/**
 * Mimics behaviour of i18n version 9.x (designed for nuxt3)
 */
export function usePrice() {
  const { locale, numberFormats, locales } = useI18n()

  const price = (value: number, currencyCode?: string) => {
    const numberFormat = numberFormats.value[locale.value]
    // @ts-ignore @ts-expect-error
    const iso = locales?.value?.find((l) => l.code === locale.value)?.iso

    return value.toLocaleString(iso, {
      style: 'currency',
      currencyDisplay: 'symbol',
      ...numberFormat?.currency,
      currency: currencyCode ?? numberFormat?.currency?.currency
    })
  }

  return {
    price
  }
}
